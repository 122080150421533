<template>
  <div id="slider-input-box" class="vac-box-footer">
    <VueSlider
      id="est-slider"
      ref="slider"
      v-model="sliderValueDataProp"
      v-bind="options"
      :dot-style="options.dotStyle"
      :tooltip="options.tooltip"
      :tooltip-placement="tooltipPlacementDirection"
      :tooltip-formatter="formatter"
      :process="false"
      @change="changed"
    >
    </VueSlider>
    <div class="vac-icon-textarea">
      <div
        class="vac-svg-button"
        @click="$emit('submitPointEstimate', sliderValueDataProp)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          width="24"
          height="24"
          :viewBox="`0 0 24 24`"
        >
          <path id="vac-icon-send" d="M2,21L23,12L2,3V10L17,12L2,14V21Z" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

export default {
  name: "SliderInput",
  components: {
    VueSlider,
  },
  props: {
    sliderValue: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      formatter: "{value}%",
      tooltipPlacementDirection: "left",
      sliderValueDataProp: 50,
      options: {
        dotSize: 40,
        width: "auto",
        height: 10,
        contained: false,
        direction: "ltr",
        data: null,
        dataLabel: "label",
        dataValue: "value",
        min: 0,
        max: 100,
        interval: 1,
        disabled: false,
        clickable: true,
        duration: 0.5,
        adsorb: false,
        lazy: false,
        tooltip: "always",
        tooltipFormatter: void 0,
        useKeyboard: false,
        keydownHook: null,
        dragOnClick: true,
        enableCross: true,
        fixed: false,
        minRange: void 0,
        maxRange: void 0,
        order: true,
        marks: false,
        dotOptions: void 0,
        dotAttrs: void 0,
        dotStyle: { boxShadow: "0 0 2px 1px black", borderColor: "black" },
        railStyle: { backgroundColor: "white" },
        process: (dotsPos) => [[0, dotsPos[0], { backgroundColor: "black" }]],
        processStyle: void 0,
        tooltipStyle: {
          backgroundColor: "white",
          color: "black",
          fontSize: "16px",
          radius: "60px",
        },
        stepStyle: void 0,
        stepActiveStyle: void 0,
        labelStyle: void 0,
        labelActiveStyle: void 0,
      },
    };
  },
  watch: {
    sliderValue() {
      this.sliderValueDataProp = this.sliderValue;
    },
  },
  methods: {
    changed() {
      const currentSliderValue = this.$refs.slider.getValue();
      this.tooltipLocationToggle(currentSliderValue);
    },
    tooltipLocationToggle(value) {
      if (value <= 25) {
        this.tooltipPlacementDirection = "right";
      } else if (value >= 75) {
        this.tooltipPlacementDirection = "left";
      }
    },
  },
};
</script>

<style>
#est-slider {
  width: 100% !important;
  padding: 15px !important;
}
</style>
